<template>
    <div class="col-12">
        <div class="d-flex-wrap imovel">
            <figure class="col-lg-3 col-12 px-0" v-if="imovel.imagem">
                <a :target="configuracao_layout.abrir_imoveis_nova_janela?'_blank':undefined" :href="`imovel/${imovel.codigo.replace(/\s+/igm,'')}/${imovel.slug}`" :title="imovel.titulo">
                    <picture>
                        <source v-for="media in imovel.imagem.media" :srcset="`${media.url}, ${media.size}w`" :type="media.format" />
                        <img class="lazy-cover loaded" :src="imovel.imagem.url" :alt="imovel.titulo">
                    </picture>
                </a>
            </figure>
            <span class="col-lg-6 i my-auto">
                <small class="cod">CÓDIGO {{ imovel.codigo }}</small>
                <h2>
                    {{ imovel.tipo?.titulo }} em {{ imovel.cidade.titulo }}
                    <span>{{ imovel.bairro.titulo }}</span>
                </h2>
                <p class="d-none d-md-inline-block">
                    {{ descricao }} 
                    <!-- precisa reduzir -->
                </p>
            </span>
            <span class="col-lg-3 col-12 ct">
                <ul>
                    <li v-if="imovel.dormitorios">
                        <img class="loaded" src="@assets/img/quarto.svg?url" width="17" alt="Dormitórios">
                        <span>
                            <b>{{ imovel.dormitorios }}</b>
                            DORM.
                        </span>
                    </li>
                    <li v-if="imovel.suites">
                        <img class="loaded" width="17" src="@assets/img/suite.svg?url" alt="Suítes">
                        <span>
                            <b>{{ imovel.suites }}</b>
                            SUÍTE{{ imovel.suites != 1 ? 'S' : '' }}
                        </span>
                    </li>
                    <li v-if="imovel.area_privativa">
                        <img class="loaded" width="17" src="@assets/img/area.svg?url" alt="Área">
                        <span>
                            <b>{{ Math.round(imovel.area_privativa) }}</b>
                            M²
                        </span>
                    </li>
                    <li v-if="imovel.vagas">
                        <img class="loaded" src="@assets/img/vaga.svg?url" width="17" alt="Vagas">
                        <span>
                            <b>{{ imovel.vagas }}</b>
                            VAGA{{ imovel.vagas != 1 ? 'S' : '' }}
                        </span>
                    </li>
                </ul>
                <span class="valor" v-if="imovel.venda && imovel.valor_venda">
                    {{ number_formatter(imovel.valor_venda) }}
                </span>
                <span class="valor" v-if="imovel.locacao && imovel.valor_locacao">
                    {{ number_formatter(imovel.valor_locacao) }}
                </span>
                <a :target="configuracao_layout.abrir_imoveis_nova_janela ? '_blank' : undefined" :href="`imovel/${imovel.codigo}/${imovel.slug}`" :title="imovel.titulo" class="bt-contatar">MAIS
                    DETALHES</a>
            </span>
        </div>
    </div>
</template>
<script lang="ts" setup>
import { Imovel } from '@/store/imoveis';
import { computed } from 'vue';
import { configuracao_layout } from '@/store/configuracao-layout';


interface Props {
    imovel: Imovel
}

const props = defineProps<Props>()

const number_formatter = (value: number): string => {
    return new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL', maximumFractionDigits: 0 }).format(value)
}

import $ from 'jquery'

const descricao = computed(() => {
    
    let descricao = $(`<div>${props.imovel.descricao||''}</div>`).text()
    let words = descricao.split(/\s+/igm)
    let result = []
    let count = 0
    let elipsis = ''
    for(let word of words) {
        if (count + word.length >= 260) {
            elipsis = '...'
            break
        }
        result.push(word)
        count+=word.length
    }
    return result.join(' ') + elipsis

})

</script>
<style lang="less" scoped>
    picture {
        img {
            aspect-ratio: 16/9;
            object-fit: cover;
        }
    }
</style>